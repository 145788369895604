* {
  box-sizing: border-box;
}

.container {
    display: flex;
    align-items: center;
    padding: 20px;

}

.line {
    width: 97%;
    display: flex;
    height: 5px;
    background-color: black;
    margin:  20px;
    border-radius: 5px;
}
 /* our mission */
 .container {
    display: flex;
    align-items: flex-start;
  }
  
  .card-container {
    justify-content: space-around; 
    /* padding: 20px; */
  }
  
  .card {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    /* padding: 10px; */
    width: 250px;
    height: 220px;
    background-color: black ;
  }
  .card:hover{
    background-color: black;
    box-shadow: 10px 8px 16px 0  rgb(107, 181, 220);
  }
  .avatar {
    width: 80%;
    height: 60%;
  }
  
  .container {
    text-align: center;
    margin-top: 20px;
  }
  
  h4 {
    margin: 0;
  }
  
  .all {
    display: flex;
    /* flex-direction: row; */
    margin-top: 20px;
  }

  .vertical-line {
    width: 5px;
    height: 100px;
    background-color: black;
    border-radius: 4px;
    margin-right: 30px;
  }


  .div{
    background-color:rgba(0,0,20,0.2);
    padding:160px
   }

   .about-image{
    width:100%;
    height: auto;
    display: block;
    /* min-width:500px; */
    max-Width:700px;
    /* padding: 40px; */
   box-shadow: 10px -10px 0px  rgb(204, 130, 130) ;
   

  }

  .d-flex {
    display: flex;
    /* flex-direction: row; */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .content-container {
    display: flex;
    /* flex-direction: row; */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    flex: 1; /* Take up full width */
    max-width: 50%; /* Maximum width for each container */
  }
  
  .image-container {
    text-align: center; /* Center align the image */
    width: 50%;
  }
  
  .text-container {
    text-align: center;
    color: black;
  }

  @media screen and (max-width: 700px) {
    .container {
    text-align: center;
      width: 100%;
      display: grid;
    }
    .all{
      width:100%;
      /* display: grid; */
      flex-direction: column;
    }
    .vertical-line {
      width: 100%;  /* Convert the width to height */
      height: 5px;   /* Convert the height to width */
      margin: 10px 0;  /* Adjust margin to space out elements */
      /* margin-left: 100px; */
      }
      .div{
        display: 100;
      }
      .card-container{
        display: flex;
        width: 200%;
        /* height: 300%; */
      }
  .card{
    width: 100%; 
    margin: 5px;
  }

  .about-image{
    width:100%;
    height: auto;
  }
  .content-container {
    flex-direction: column; /* Stack content vertically on smaller screens */
    max-width: 100%;
  }
  .image-container{
    width: 100%;
  }
  .line{
    /* margin: 20px; */
    width: 90%;
  }
    
  }