.appbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: black; /* Set your desired background color */
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .appbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px; /* Set your desired maximum width */
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .appbar-logo {
    color: white; /* Set your desired text color */
    font-size: 2rem;
    text-decoration: none;
  }
  
  .menu-icon {
    display: none; /* Hide initially on larger screens */
    cursor: pointer;
    color: white; /* Set your desired color */
    font-size: 1.5rem;
  }
  
  .nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  .app-item {
    margin-right: 5px;
    text-decoration: none;
    color: white; /* Set your desired text color */
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    letter-spacing: 1px;
    padding: 15px;
    font-weight:500;
  }
  
  .app-item:hover {
    color: rgb(163, 30, 30); /* Set your desired hover color */
  }
  
  .dropdown-menu {
    display: none; /* Initially hide */
  }
  
  .dropdown-menu.active {
    display: block; /* Show when active */
  }
  
  
  /* Media Query for mobile responsiveness */
  @media screen and (max-width: 500px) {
    .menu-icon {
      display: block;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px; /* Adjust as needed */
      left: 0;
      background-color: black; /* Set your desired background color */
      text-align: center;
      opacity: 0.8;
      transition: all 0.3s ease-in-out;
      visibility: hidden;
    }
  
    .nav-menu.active {
      visibility: visible;
    }
  
    .app-item {
      margin: 10px 0;
    }
  }
  