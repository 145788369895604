.about-images{
    width:600px;
    height: 25x0;
    margin-right: 10px;
    box-shadow: 0px 0px 15px  #1b7dff ;
  }

  @media screen  and ( max-width:566px){
    .about-images{
      width: 100%;
      height: auto;
    }
    .about-images1{
      width: 100%;
      height: auto;
      display: none;
    }
    
  }