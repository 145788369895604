.s_imgR{
    width: 550px;
    height: 500px;
    /* max-width: 700px;
    min-width: 300px; */
    padding-top: 70px;
}

.s_imgL{
    width: 550px;
    height: 500px;
    /* max-width: 700px;
    min-width: 300px; */
    padding-top: 70px;
}

.sec_p{
    width:100%;
    min-width: 300px;
    color:GREY
}

  
  
@media screen and (max-width:700px) {
    .s_imgR{
        /* width: 350px;
        height: 300px;
        align-items: center; */
        display: none;
    }
    .s_imgL{
        display: none;
        /* width: 350px;
        height: 300px;
        align-items: center; */
    }
}